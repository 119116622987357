.project-card {
	height: 210px;
	background-color: #3d5961;
	margin-bottom: 30px;
	border-radius: 10px;
	overflow: hidden;

	@media (max-width: 475px) {
		height: 190px;
	}

	&__left-part {
		display: inline-block;
		width: 35%;
		height: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		float: left;
		position: relative;
		overflow: hidden;
	}

	&__image {
		position: relative;
		float: left;
		cursor: pointer;

		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgba(61, 89, 97, 0.5);
			transition: all 0.3s linear;
		}
	}

	&__button-container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -20%);
		opacity: 0;
		transition: transform 0.25s ease-in-out;
		width: 100%;
		text-align: center;
	}

	&:hover &__left-part {
		filter: drop-shadow(2px 4px 6px black);
	}

	&:hover &__button-container {
		opacity: 1;
		transform: translate(-50%, -50%);
	}

	&:hover &__image:before {
		background: rgba(61, 89, 97, 0.75);
	}

	&__right-part {
		display: inline-block;
		width: 65%;
		height: 100%;
		padding: 15px 30px;

		@media (max-width: 930px) {
			padding: 15px 40px;
		}

		@media (max-width: 930px) {
			padding: 15px 0px 15px 50px;
		}

		@media (max-width: 870px) {
			padding: 15px 0px 15px 20px;
		}
	}
}
