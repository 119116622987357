.row {
	max-width: $grid-width;
	margin: 0 auto;

	@include clearfix;

	&:not(:last-child) {
		margin-bottom: $gutter-vertical;
	}

	[class^="col-"] {
		float: left;

		&:not(:last-child) {
			margin-right: $gutter-horizontal-big;

			@media (max-width: 910px) {
				margin-right: $gutter-horizontal-small;
			}
		}
	}

	.col-1-of-2 {
		width: calc((100% - #{$gutter-horizontal-big}) / 2);
	}

	.col-1-of-3 {
		width: calc((100% - 2 * #{$gutter-horizontal-big}) / 3);
	}

	.col-1-of-4 {
		width: calc((100% - 3 * #{$gutter-horizontal-big}) / 4);

		@media (max-width: 780px) {
			width: 100%;
		}
	}

	.col-2-of-3 {
		width: calc(
			(2 * (100% - 2 * #{$gutter-horizontal-big}) / 3) + #{$gutter-horizontal-big}
		);
	}

	.col-2-of-4 {
		width: calc(
			(2 * (100% - 3 * #{$gutter-horizontal-big}) / 4) + #{$gutter-horizontal-big}
		);
	}

	.col-3-of-4 {
		width: calc(
			(3 * (100% - 3 * #{$gutter-horizontal-big}) / 4) + 2 * #{$gutter-horizontal-big}
		);

		@media (max-width: 780px) {
			width: 100%;
		}
	}
}
