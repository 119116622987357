.footer {
	background-color: $color-primary-dark;
	padding: 3rem;
	color: $color-grey-shade;
	text-align: center;
	font-size: 1.4rem;

	// position: absolute;
	// bottom: 0;
	// width: 100%;
}
