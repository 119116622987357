.u-margin-bottom-small { margin-bottom: 2rem !important;}

.u-margin-bottom-medium { margin-bottom: 4rem !important;}

.u-margin-bottom-large { margin-bottom: 6rem !important;}

.u-margin-bottom-huge { margin-bottom: 8rem !important;}

.u-margin-right-small { margin-right: 3rem !important;}

.u-margin-null { margin: 0 !important; }

.u-margin-bottom-null { margin-bottom: 0 !important; }

.u-text-right { text-align: right !important;}

.u-text-left { text-align: left !important;}