// COLORS

$color-white: #fff;
$color-black: #000;
$color-grey-shade: #c4c4c4;
$color-primary-light: #3d5961;
$color-primary-dark: #0c1218;
$color-secondary-light: #52616b;
$color-secondary-dark: #1e2022;
$color-dark-shade: #383636;
$color-table-border-bottom: #dddddd;
$color-table-row-even: #f3f3f3;
// SIZES

$text-size-default: 1.6rem;
$text-weight-heading-1: 400;
$text-weight-heading-2: 300;

// GRID

$grid-width: 120rem;
$gutter-horizontal-big: 6rem;
$gutter-horizontal-small: 3rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;

// SIZES
$size-laptop-small: 1200px;
